@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        scroll-behavior: smooth;
    }
}

@layer components {
    .main-gradient {
        @apply bg-gradient-to-tl from-primary to-accent;
    }
}